import Vue from 'vue';
import instantsearch from 'instantsearch.js/es';
import { warn } from './warn';

function walkIndex(indexWidget, visit) {
  visit(indexWidget);

  return indexWidget.getWidgets().forEach(widget => {
    if (widget.$$type !== 'ais.index') return;
    visit(widget);
    walkIndex(widget, visit);
  });
}

function renderToString(app, _renderToString) {
  return new Promise((resolve, reject) =>
    _renderToString(app, (err, res) => {
      if (err) reject(err);
      resolve(res);
    })
  );
}

function searchOnlyWithDerivedHelpers(helper) {
  return new Promise((resolve, reject) => {
    helper.searchOnlyWithDerivedHelpers();

    // we assume all derived helpers resolve at least in the same tick
    helper.derivedHelpers[0].on('result', () => {
      resolve();
    });

    helper.derivedHelpers.forEach(derivedHelper =>
      derivedHelper.on('error', e => {
        reject(e);
      })
    );
  });
}

function defaultCloneComponent(componentInstance) {
  const options = {
    serverPrefetch: undefined,
    fetch: undefined,
    _base: undefined,
    name: 'ais-ssr-root-component',
    // copy over global Vue APIs
    router: componentInstance.$router,
    store: componentInstance.$store,
  };

  const Extended = componentInstance.$vnode
    ? componentInstance.$vnode.componentOptions.Ctor.extend(options)
    : Vue.component(
        options.name,
        Object.assign({}, componentInstance.$options, options)
      );

  const app = new Extended({
    propsData: componentInstance.$options.propsData,
  });

  // https://stackoverflow.com/a/48195006/3185307
  app.$slots = componentInstance.$slots;
  app.$root = componentInstance.$root;
  app.$options.serverPrefetch = [];

  return app;
}

function augmentInstantSearch(instantSearchOptions, cloneComponent) {
  const search = instantsearch(instantSearchOptions);

  let initialResults;

  /**
   * main API for SSR, called in serverPrefetch of a root component which contains instantsearch
   * @param {object} componentInstance the calling component's `this`
   * @returns {Promise} result of the search, to save for .hydrate
   */
  search.findResultsState = function(componentInstance) {
    let _renderToString;
    try {
      _renderToString = require('vue-server-renderer/basic');
    } catch (e) {
      // error is handled by regular if, in case it's `undefined`
    }
    if (!_renderToString) {
      throw new Error('you need to install vue-server-renderer');
    }

    let app;
    let instance;

    return Promise.resolve()
      .then(() => {
        app = cloneComponent(componentInstance);

        instance = app.instantsearch;

        instance.start();
        // although we use start for initializing the main index,
        // we don't want to send search requests yet
        instance.started = false;
      })
      .then(() => renderToString(app, _renderToString))
      .then(() => searchOnlyWithDerivedHelpers(instance.mainHelper))
      .then(() => {
        initialResults = {};
        walkIndex(instance.mainIndex, widget => {
          const { _state, _rawResults } = widget.getResults();

          initialResults[widget.getIndexId()] = {
            // copy just the values of SearchParameters, not the functions
            state: Object.keys(_state).reduce((acc, key) => {
              // eslint-disable-next-line no-param-reassign
              acc[key] = _state[key];
              return acc;
            }, {}),
            results: _rawResults,
          };
        });

        search.hydrate(initialResults);
        return search.getState();
      });
  };

  /**
   * @returns {Promise} result state to serialize and enter into .hydrate
   */
  search.getState = function() {
    if (!initialResults) {
      throw new Error('You need to wait for findResultsState to finish');
    }
    return initialResults;
  };

  /**
   * make sure correct data is available in each widget's state.
   * called in widget mixin with (this.widget, this)
   *
   * @param {object} widget The widget instance
   * @param {object} parent The local parent index
   * @returns {void}
   */
  search.__forceRender = function(widget, parent) {
    const results = parent.getResults();

    // this happens when a different InstantSearch gets rendered initially,
    // after the hydrate finished. There's thus no initial results available.
    if (results === null) {
      return;
    }

    const state = results._state;

    const localHelper = parent.getHelper();
    // helper gets created in init, but that means it doesn't get the injected
    // parameters, because those are from the lastResults
    localHelper.state = state;

    widget.render({
      helper: localHelper,
      results,
      scopedResults: parent.getScopedResults(),
      parent,
      state,
      templatesConfig: {},
      createURL: parent.createURL,
      instantSearchInstance: search,
      searchMetadata: {
        isSearchStalled: false,
      },
    });
  };

  /**
   * Called both in server
   * @param {object} results a map of indexId: SearchResults
   * @returns {void}
   */
  search.hydrate = function(results) {
    if (!results) {
      warn(
        'The result of `findResultsState()` needs to be passed to `hydrate()`.'
      );
      return;
    }

    search._initialResults = results;

    search.start();
    search.started = false;
  };
  return search;
}

export function createServerRootMixin(instantSearchOptions = {}) {
  const { $cloneComponent = defaultCloneComponent } = instantSearchOptions;

  const search = augmentInstantSearch(instantSearchOptions, $cloneComponent);

  // put this in the user's root Vue instance
  // we can then reuse that InstantSearch instance seamlessly from `ais-instant-search-ssr`
  const rootMixin = {
    provide() {
      return {
        $_ais_ssrInstantSearchInstance: this.instantsearch,
      };
    },
    data() {
      return {
        // this is in data, so that the real & cloned render do not share
        // the same instantsearch instance.
        instantsearch: search,
      };
    },
  };

  return rootMixin;
}
